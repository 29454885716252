import React, { useState, useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { renderToString } from 'react-dom/server'
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  // Radio,
  // FormControlLabel,
  // RadioGroup,
  // FormLabel,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CompToPrintInv from '../reports/CompToPrintInv';
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { currentUser } from "../login/loginSlice";
import { bankDetails, termsAndConditions } from "../common/lookupSlice"
import { custTypes, paymentMethods } from "../common/lookupSlice"
import { useForm, useFieldArray } from "react-hook-form";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
// import FirstPageIcon from '@material-ui/icons/FirstPage';
import EditIcon from "@material-ui/icons/Edit";
import calculateIcon from '../../images/calculate.svg';
import calculateDisabledIcon from '../../images/calculator_disabled.svg';
import infoIcon from '../../images/info.svg';
import ErrInvoiceReportIcon from "../../images/ErrInvoiceReport.svg";
import infoIconDisabled from '../../images/infoDisabled.svg';
import discountIcon from '../../images/discountIcon.svg';
import SearchProduct from "../productGroups/searchProductSale";
import axios from "axios";
// import Payment from '../RecordSaleNew/paymentOffline';
import Payment from '../recordSales/Payment';
/* import Payment from '../RecordSaleNew/payment'; */
import DeleteSale from '../recordSales/deleteSale';
import Calculator from '../recordSales/calculator';
import CustomerEditForm from '../recordSales/CustomerEditForm';
import ConsigneeForm from '../recordSales/ConsigneeForm';
import DispatchAddressForm from '../recordSales/DispatchAddressForm';
import EWayBillDetailsForm from '../recordSales/eWayBillDetailsForm';
import PriceDetermine from '../recordSales/priceDetermination';
import Discount from './discounts';
import ErrorLists from './errorLists';
import Po from './po';
// import RazorpayPGW from "./razorpayPGW";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  openModal,
  closeModal,
  modalInProcessing
} from "../common/confirmationDialogSlice";
import moment from 'moment';
import "./recordSale.css";


const RecordSaleSlide = (props) => {
  const loggedInUser = useSelector(currentUser);
  const orgBankDetails = useSelector(bankDetails);
  const orgTermsAndConditions = useSelector(termsAndConditions);
  const { id } = useParams();
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const orgName = loggedInUser && loggedInUser.org && loggedInUser.org.name;
  const CompanyERPId = loggedInUser && loggedInUser.org && loggedInUser.org.ERPId;
  const COMPANY_NAME_IN_ERPNEXT = CompanyERPId;
  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN" || role.name === "PRICING ADMIN") > -1 ? true : false;
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  let isGenerateEInvoice = loggedInUser && loggedInUser.org && loggedInUser.org.isGenerateEInvoice ? true : false;
  const componentRefInv = useRef();
  const buttonRef = useRef();
  const token = localStorage.getItem("authToken");
  const history = useHistory();
  const dispatch = useDispatch();
  const types = useSelector(custTypes);
  const allInventoryLocations = loggedInUser && loggedInUser.locations ? loggedInUser.locations : [];
  const inventoryLocations = allInventoryLocations.filter((loc) => { return loc.type !== "CORPORATE" && loc.GSTIN });
  const defaultLoc = inventoryLocations.find(loc => loc.isPrimary === true);
  const initialLoc = (defaultLoc) ? defaultLoc.id : inventoryLocations && inventoryLocations.length > 0 && inventoryLocations[0].id;
  const [costCenterName, setCostCenterName] = useState('');
  const [store, setStore] = useState(!(id) && initialLoc);
  const [locName, setLocName] = useState('');
  const [bankId, setBank] = useState("");
  const [bankName, setBankName] = useState('');
  const [custPO, setCustPO] = useState('');
  const [calculate, setCalculate] = useState(null);
  const [payments, setPayments] = useState({ open: false });
  const [deleteInv, setDeleteInv] = useState({ open: false });
  const [isSaved, setIsSaved] = useState(isAuditor ? true : false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [isSaleWarning, setIsSaleWarning] = useState(false);
  const [isSaleMsgWarning, setIsSaleMsgWarning] = useState(false);
  const [safetyStockWarning, setSafetyStockWarning] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showInvErr, setShowInvErr] = useState(false);
  const [showPo, setShowPo] = useState(false);
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [transportDetails, setTransportDetails] = useState({
    dispatchDocNo: "",
    dispatchThrough: "",
    distanation: "",
    vehicleNo: "",
    vehicleLLR: ""
  });
  const [remarkNote, setInvoiceRemark] = useState("")
  const [paymentDetails, setPaymentDetails] = useState({});
  const [isTransportError, setTransportError] = useState(false);
  const [isTransDetailsMandtory, setIsTransportDetails] = useState(false);
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [emailSendData, setEmailSendData] = useState({
    emailId: '',
    emailSubject: ''
  });
  let { emailId, emailSubject } = emailSendData;
  const [invoiceNo, setInvoiceNo] = useState('');
  const [tempInvNo, setTempInvNo] = useState('');
  const [erpInvNo, setERPInvNo] = useState('');
  const [invoice, setInvoice] = useState([]);
  const [message, setMessage] = useState('');
  const [priceData, setPriceData] = useState({ open: false });
  const [productPriceData, setProductPriceData] = useState({ open: false });
  const [selectProduct, setSelectProduct] = useState({ open: false, test: null });
  const [invEdit, setInvEdit] = useState(id ? (id.toString().charAt(0) === 'D') ? false : true : false);
  const [discountInfo, setDiscountInfo] = useState([]);
  const [dis, setDis] = useState([]);
  const [pro, setPro] = useState([]);
  const [selectedPo, setSelectedPo] = useState({ poNum: "", products: [] });
  const isPo = selectedPo.poNum;
  const location = useLocation();
  const customerData = location.state;
  const CustomerId = customerData && customerData.id;
  const [isEInvoice, setEInvoice] = useState(false);
  const [isEWayBill, setEWayBill] = useState(false);
  let custTypeId = customerData && customerData.custTypeId;
  let orgCode = customerData && customerData.orgCode;
  const [orgDetails, setOrgDetails] = useState(null);
  const [HSNCodeData, setHSNData] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [bankList, setOrgBankListData] = useState([]);
  const [errHSNProIndex, setErrHSN] = useState([]);
  const [errQtyProIndex, setErrQtyProd] = useState([]);
  const [sellerDetails, setSellerDetails] = useState(null);
  const [isCustWholeSale, setCustWholeSale] = useState(false);
  const [showCustForm, setShowCustForm] = useState(false);
  const [editCustDetails, setEditCustDetails] = useState(null);
  const [showConsingeeForm, setShowConsingeeForm] = useState(false);
  const [editConsigneeDetails, setEditConsingeeDetails] = useState(null);
  const [showDispatchForm, setShowDispatchForm] = useState(false);
  const [editDispatchDetails, setEditDispatchDetails] = useState(null);
  const [showEWayBillForm, setShowEWayBillForm] = useState(false);
  const [editEWayBillDetails, setEditEWayBillDetails] = useState(null);
  const [eInvEWBErrorDetails, setErrorDetails] = useState([]);
  const [isBDNotFilled, setBDNotFilled] = useState(false);
  const [isCDNotFilled, setCDNotFilled] = useState(false);
  const [isDDNotFilled, setDDNotFilled] = useState(false);
  const [isEDNotFilled, setEDNotFilled] = useState(false);
  const [isRequiredDetailsNot, setRequiredDetailsNot] = useState(false);
  const [isEditAll, setEditAllDetails] = useState(true);
  const [isEditEWB, setEditEWBDetails] = useState(true);

  /* Payment Gateway Changes */
  const [isPaymentMethod, setIsPaymentMethod] = React.useState(false);
  const payMethods = useSelector(paymentMethods);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodCode, setPaymentMethodCode] = useState(null);
  const [isConfirmBox, setIsConfirmBox] = React.useState(false);

  const onPaymentmethodChange = async (event) => {
    let paymentMethodId = Number(event.target.value);
    console.log("Check", isPaymentMethod, paymentMethod, paymentMethodCode);
    setPaymentMethod(paymentMethodId);
    let findPaymentMethodCode = await payMethods.find((pmData) => {
      return (pmData.id === paymentMethodId) ? pmData : null;
    })
    if (findPaymentMethodCode) {
      setIsConfirmBox(true)
      setPaymentMethodCode(findPaymentMethodCode.code)
      const dialogText = "Please confirm the payment process?";
      dispatch(
        openModal({
          dialogText: dialogText,
          secondayBtnText: "Yes",
          primaryBtnText: "Cancel",
          handleCloseModal: confirmDialogBox,
        })
      );
    }

  }

  const confirmDialogBox = async (value) => {
    if (value) {
      dispatch(modalInProcessing());
      if (tempInvNo && erpInvNo) {
        setIsPaymentMethod(value)
        dispatch(closeModal());
      } else {
        try {
          const clonedData = calculate ? _.cloneDeep(calculate) : {}
          //Below assignments needs to be updated
          const total = clonedData.total;
          const roundOff = total ? (Math.round((Math.round(total) - total) * 100) / 100).toFixed(2) : 0;
          clonedData.roundOff = Number(roundOff);
          let poNumber = isPo ? selectedPo.poNum : custPO;
          if (poNumber) {
            clonedData.poNum = poNumber
          }
          clonedData.orgCode = customerData.orgCode;
          clonedData.customerId = customerData.id;
          clonedData.custTypeId = customerData.custTypeId;
          clonedData.locationId = store;
          clonedData.costCenter = costCenterName;
          clonedData.orgBankId = bankId;
          clonedData.invoiceDate = moment().format('MM/DD/YYYY');
          clonedData.remarkNote = (remarkNote) ? remarkNote : "";
          clonedData.isEWayBill = (isEWayBill) ? true : false;
          clonedData.isBtoB = (isCustWholeSale) ? true : false;
          clonedData.isEInvoice = (isEInvoice) ? true : false;
          clonedData.transportDetails = (transportDetails) ? transportDetails : {};
          clonedData.sellerDetails = (sellerDetails) ? sellerDetails : {};
          clonedData.buyerDetails = (editCustDetails) ? editCustDetails : {};
          clonedData.consigneeDetails = (editConsigneeDetails) ? editConsigneeDetails : {};
          clonedData.dispatchDetails = (editDispatchDetails) ? editDispatchDetails : {};
          clonedData.eWayBillDetails = (editEWayBillDetails) ? editEWayBillDetails : {};
          let result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/invoices`,
            clonedData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const resStatus = result && result.data && result.data.status;
          if (resStatus === "SUCCESS") {
            let invNum = result && result.data && result.data.invoiceNum;
            let erpInv = result && result.data && result.data.erpRes && result.data.erpRes.docs && result.data.erpRes.docinfo && result.data.erpRes.docinfo.name;
            setERPInvNo(erpInv)
            setTempInvNo(invNum);
            setIsPaymentMethod(value)
            dispatch(closeModal());
          } else {
            setMessage(result && result.data && result.data.message)
            setIsError(true)
            dispatch(closeModal());
            setPayments({ ...payments, open: false });
          }
        } catch (err) {
          setMessage("Something went wrong please try again.")
          setIsError(true);
          dispatch(closeModal());
          setPayments({ ...payments, open: false });

        }
      }

    } else {
      setIsPaymentMethod(value)
      dispatch(closeModal());
    }
  }




  const onEditDataCustSubmit = (data) => {
    setShowCustForm(false);
    setEditCustDetails(data);
  }
  const onEditCustCancel = () => {
    setShowCustForm(false);
  }


  const onEditConsigneeDataSubmit = (data) => {
    setShowConsingeeForm(false);
    setEditConsingeeDetails(data);
  }
  const onEditConsigneeCancel = () => {
    setShowConsingeeForm(false);
  }


  const onEditDispatchDataSubmit = (data) => {
    setShowDispatchForm(false);
    setEditDispatchDetails(data);
  }
  const onEditDispatchCancel = () => {
    setShowDispatchForm(false);
  }


  const onEditEWayBillDataSubmit = (data) => {
    setShowEWayBillForm(false);
    setEditEWayBillDetails(data);
  }
  const onEditEWayBillCancel = () => {
    setShowEWayBillForm(false);
  }

  const { handleSubmit, control, errors, reset, register, setValue, getValues } = useForm({
    defaultValues: {
      sales: selectedPo.products ? selectedPo.products : [],
    },
  });

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "sales",
  });

  useEffect(() => {
    if (invoiceNo) {
      buttonRef && buttonRef.current && buttonRef.current.click()
    }
  }, [invoiceNo]);

  useEffect(() => {
    const isWholeSale = (types) ? types.find((typesData) => {
      let isReturn = false;
      if (typesData.id === custTypeId) {
        isReturn = (typesData.code === "WHLS") ? true : false;
      }
      return isReturn;
    }) : null;
    setCustWholeSale(isWholeSale);
  }, [custTypeId, types]);

  useEffect(() => {
    let eInv = (isGenerateEInvoice && isCustWholeSale) ? true : false;
    if (!invEdit) { setEInvoice(eInv) }
  }, [isCustWholeSale, isGenerateEInvoice, invEdit, id]);

  useEffect(() => {
    reset();
    setSelectedPo({ poNum: "", products: [] })
  }, [store]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedPo && selectedPo.poNum) {
      setCalculate(null);
      setDis([])
      setPro([])
      append(selectedPo.products);
    }
  }, [selectedPo]); // eslint-disable-line react-hooks/exhaustive-deps


  const loadData = async (COMPANY_NAME_IN_ERPNEXT) => {
    let count = 0;
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/customers/${CustomerId}/discounts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDiscountInfo(result.data.discounts);
      const hsncoderes = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/hsncode`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (hsncoderes.status === 200) {
        if (hsncoderes.data && hsncoderes.data.length > 0) {
          let hsnCodes = [];
          await hsncoderes.data.map((hsnData) => {
            hsnCodes.push(hsnData.code);
            return hsnData;
          })
          setHSNData(hsnCodes);
        }
      }
      if (id) {
        (id.toString().charAt(0) === 'D') ? setInvEdit(false) : setInvEdit(true);
        const result2 = (id.toString().charAt(0) === 'D') ? await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/draftsale/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ) : await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/invoices/edit/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result2 && result2.data && result2.data.invErrorList && result2.data.invErrorList.length > 0) {
          setErrorDetails(result2.data.invErrorList)
        }
        if (invEdit) {
          setEInvoice((result2 && result2.data && result2.data.isEInvoice) ? true : false);
          setEditAllDetails((result2 && result2.data && result2.data.isEInvoice && result2.data.eInvIRN && (result2.data.isInvoiceStatus === "COMPLETED")) ? false : true)
          setEditEWBDetails((result2 && result2.data && result2.data.isEInvoice && result2.data.eInvIRN && result2.data.isEWayBill && (result2.data.isEWayBillStatus === "COMPLETED")) ? false : true)
          setOrgDetails((result2 && result2.data && result2.data.org) ? result2.data.org : null);
          setSellerDetails((result2 && result2.data && result2.data.invOrgDetails) ? result2.data.invOrgDetails : null);
          setEditCustDetails((result2 && result2.data && result2.data.invCustmerDetails) ? { ...result2.data.invCustmerDetails, stateCode: result2.data.invCustmerDetails.state } : null);
          setEditConsingeeDetails((result2 && result2.data && result2.data.invShipDetails) ? { ...result2.data.invShipDetails, stateCode: result2.data.invShipDetails.state } : null);
          setEditDispatchDetails((result2 && result2.data && result2.data.invDispatchDetails) ? { ...result2.data.invDispatchDetails, stateCode: result2.data.invDispatchDetails.state } : null);
          setEditEWayBillDetails((result2 && result2.data && result2.data.invEWayDetails) ? result2.data.invEWayDetails : null);
        }
        setStore(result2.data.location?.id);
        setLocName(result2.data.location?.name);
        setCostCenterName(result2.data && result2.data.erpNextcostCenter)
        setBank(result2.data.companyBankdetails?.id);
        setBankName(result2.data.companyBankdetails?.bankName);
        result2.data.purchaseOrder && result2.data.purchaseOrder.poNum && setCustPO(result2.data.purchaseOrder.poNum);
        const newlineItems = result2.data.lineItems;
        setTransportDetails({
          ...transportDetails,
          dispatchDocNo: result2.data.dispatchDocNo,
          dispatchThrough: result2.data.dispatchThrough,
          distanation: result2.data.distanation,
          vehicleNo: result2.data.vehicleNo,
          vehicleLLR: result2.data.vehicleLLR
        });
        setInvoiceRemark(result2 && result2.data && result2.data.remarkNote)
        setPaymentDetails(result2.data.payments && result2.data.payments[0])
        // eslint-disable-next-line 
        newlineItems.map((x) => {
          count = (x.product.inventories.openingQty < 1 || !(x.product.inventories && x.product.inventories.openingQty)) && count++;
          append({
            productId: x.product.id,
            productName: x.product.productName,
            products: [{ ...x.product }],
            HSNCode: (x.HSNCode) ? x.HSNCode : x.product && x.product.HSNCode,
            qty: x.qty,
            rate: (x.productPrice) ? (Math.round(x.productPrice * 100) / 100).toFixed(2) : 0.00,
            disc: x.overridenDiscountPcnt > 0 ? x.overridenDiscountPcnt : 0,
            avail: result2.data.invoiceNum ? ((x.product.inventories && x.product.inventories.openingQty) ? (x.product.inventories.openingQty + x.qty) : (0 + x.qty)) :
              x.product.inventories.openingQty,
            uom: x.product.uom,
            isQtyDecimal: x.product.isQtyDecimal
          })
        })
      }
      (count > 0) && setIsWarning(true);
      let queryCostCenter = {
        fields: ["name", "owner"],
        filters: JSON.stringify({ company: COMPANY_NAME_IN_ERPNEXT, disabled: 0, is_group: 0 })
      }
      const resultCostCenter = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/erpnextdoctype/Cost Center`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: queryCostCenter,
        }
      );
      setCostCenterList(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data);
      setCostCenterName(resultCostCenter && resultCostCenter.data && resultCostCenter.data.data && resultCostCenter.data.data.length > 0 && resultCostCenter.data.data[0].name)
      const resultBankDetails = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/bankdetials`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      let orgBankList = (resultBankDetails.data && resultBankDetails.data.bankDetails && resultBankDetails.data.bankDetails.length > 0) ? resultBankDetails.data.bankDetails : [];
      setOrgBankListData(orgBankList);
      const defaultBank = orgBankList.find(bank => bank.isPrimary === true);
      const initialBank = (defaultBank) ? defaultBank.id : orgBankList && orgBankList.length > 0 && orgBankList[0].id;
      setBank(initialBank);
    }
    catch (err) {
      setDiscountInfo([]);
      if (err && err.response && err.response.data && err.response.data.message !== `Customer Discounts Not found`) {
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    loadData(COMPANY_NAME_IN_ERPNEXT);
  }, [COMPANY_NAME_IN_ERPNEXT]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const loadOrgData = async (code) => {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/org/${code}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setOrgDetails(result.data);
        let sellerData = {
          GSTIN: result.data && result.data.GSTIN,
          tradeName: result.data && result.data.name,
          address1: result.data && result.data.address1,
          address2: result.data && result.data.address2,
          address3: null,
          locality: result.data && result.data.address3,
          pincode: result.data && result.data.pincode,
          state: result.data && result.data.state,
          phoneNo: result.data && result.data.phoneNo,
          email: result.data && result.data.email
        };
        setSellerDetails(sellerData);
        let editCust = {
          tradeName: customerData.fullName,
          email: customerData.email,
          mobileNumber: customerData.mobileNumber,
          address1: customerData.address1,
          address2: customerData.address2,
          address3: customerData.address3,
          pincode: customerData.pincode,
          locality: customerData.locality,
          state: customerData.state,
          GSTIN: customerData.GSTIN,
          stateCode: customerData.state
        }
        setEditCustDetails(editCust);
        let editConsignee = {
          tradeName: customerData.fullName,
          address1: customerData.address1,
          address2: customerData.address2,
          address3: customerData.address3,
          pincode: customerData.pincode,
          locality: customerData.locality,
          state: customerData.state,
          GSTIN: customerData.GSTIN,
          stateCode: customerData.state
        }
        setEditConsingeeDetails(editConsignee);
        let editDispatch = {
          tradeName: result.data && result.data.name,
          address1: result.data && result.data.address1,
          address2: result.data && result.data.address2,
          locality: result.data && result.data.address3,
          pincode: result.data && result.data.pincode,
          state: result.data && result.data.state,
          stateCode: result.data && result.data.state
        }
        setEditDispatchDetails(editDispatch);
      }
    };
    if (!invEdit) {
      loadOrgData(orgCode);
    }
  }, [orgCode, customerData, token, invEdit, id]);


  const getCustomerInvoice = async () => {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/${invoiceNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const sales = result.data && result.data;
      return sales;
    }
    catch (err) {
      setIsError(err)
    }
  }

  const handleInvoicePrint = async () => {
    try {
      const data = await getCustomerInvoice();
      setInvoice(data);
    } catch (err) {
      setIsError(true);
    }
  }

  const openEmailInovice = async () => {
    try {
      const data = await getCustomerInvoice();
      setInvoice(data);
      setSendInvoice(true);
      setEmailSendData({
        emailSubject: `${orgName} - Invoice`,
        emailId: customerData && customerData.email
      })
    } catch (err) {
      setIsError(true);
    }
  }
  const onCloseSendInvoice = () => {
    setSendInvoice(false);
    setEmailSendData({ emailId: '', emailSubject: '' })
  }
  const onSendEmailInvoice = async () => {
    let isEmail = false;
    let isSubject = (emailSubject && emailSubject.length > 1) ? true : false;
    if (emailId && emailId.length > 0) {
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds, (data) => {
        if (!mailformat.test(String(data).toLowerCase())) {
          isEmail = false;
        }
      })
    }
    if (isEmail && isSubject) {
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = () => {
        return (
          <CompToPrintInv ref={componentRefInv} customerInv={invoice} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        )
      }
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to', emailId)
      bodyFormData.append('subject', emailSubject)
      bodyFormData.append('invoice', renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if (result.status === 200) {
        setIsSuccess(true)
        setMessage(result.data && result.data.message)
        setSendInvoice(false);
        setEmailSendData({ emailId: '' })
      }
    } else {
      if (!isEmail) {
        setEmailHelperText(true);
      }
      if (!isSubject) {
        setEmailSubjectHelperText(true);
      }
    }
  }
  const onCalculateClick = (index) => {
    const values = getValues({ nest: true })["sales"];
    const rowValues = values[index];
    setPriceData({ ...priceData, productId: rowValues.productId, productName: rowValues.productName, amount: rowValues.rate, qty: rowValues.qty, discount: rowValues.disc, open: true });
  }

  const onInfoClick = (index) => {
    setProductPriceData({ ...productPriceData, pricingInfo: productPriceData[index] && productPriceData[index].pricingInfo && productPriceData[index].pricingInfo[0], open: true });
  }

  const onSubmit = async (data) => {
    let count = 0;
    try {
      const req = {
        customerId: customerData.id,
        custTypeId: customerData.custTypeId,
        locationId: store
      }
      let errHSN = [];
      let errqtyProd = [];
      const lineItems = data && data.sales && data.sales.map((x, y) => {
        let isErr = (_.includes(HSNCodeData, x.HSNCode)) ? false : true;
        let isQtyErr = false;
        if (x.isQtyDecimal === "false") {
          if (!(Number.isInteger(parseFloat(x.qty)))) {
            isQtyErr = true;
          }
        }
        if (isErr) {
          errHSN.push(y);
        }
        if (isQtyErr) {
          errqtyProd.push(y)
        }
        (x.avail <= 0) && count++;
        let item = {
          productId: x.productId && Number(x.productId),
          qty: x.qty && Number(x.qty),
          overridenHSNCode: x.HSNCode && Number(x.HSNCode),
        }
        if (selectedPo.poNum) {
          item.overridenUnitPrice = x.HSNCode && parseFloat(x.rate);
          item.overridenDiscountPcnt = x.HSNCode && parseFloat(x.disc);
        }
        if (!(selectedPo.poNum)) {
          // pro[y] === x.rate || (pro.length === 0 && x.actualRate === x.rate) ?
          //   x.actualRate !== x.rate ? item.overridenUnitPrice = Number(x.rate) : "" :
          //   item.overridenUnitPrice = Number(x.rate)
          if (pro[y] === x.rate || (pro.length === 0 && x.actualRate === x.rate)) {
            if (x.actualRate !== x.rate) {
              item.overridenUnitPrice = Number(x.rate)
            }
          }
          else {
            item.overridenUnitPrice = Number(x.rate)
          }

          // dis[y] === x.disc || (dis.length === 0 && x.disc === "0.00") ?
          //   x.disc !== "0.00" ? item.overridenDiscountPcnt = Number(x.disc) : "" :
          //   item.overridenDiscountPcnt = Number(x.disc)

          if (dis[y] === x.disc || (dis.length === 0 && x.disc === "0.00")) {
            if (x.disc !== "0.00") {
              item.overridenDiscountPcnt = Number(x.disc)
            }
          }
          else {
            item.overridenDiscountPcnt = Number(x.disc)
          }
        }
        return item;
      })
      req.lineItems = lineItems;
      (selectedPo.poNum) && (req.poNum = selectedPo.poNum);
      if (errqtyProd.length === 0) {
        if (errHSN.length === 0) {
          setErrHSN([]);
          setErrQtyProd([]);
          if (count > 0) {
            setIsWarning(true);
          } else {
            req.customerState = editCustDetails && editCustDetails.stateCode;
            const result = await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
              req,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            const cloneDis = _.cloneDeep(dis)
            const clonePro = _.cloneDeep(pro)
            const salesValue = result.data.lineItems.map((x, y) => {
              if (!(cloneDis[y])) {
                cloneDis[y] = x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00'
              }
              if (!(clonePro[y])) {
                clonePro[y] = x.unitPrice ? (Math.round(x.unitPrice * 100) / 100).toFixed(2) : '0.00'
              }
              return {
                productId: x.productId,
                productName: x.productName,
                HSNCode: (x.HSNCode) ? x.HSNCode : x.product && x.product.HSNCode,
                qty: x.qty,
                rate: x.unitPrice ? (Math.round(x.unitPrice * 100) / 100).toFixed(2) : '0.00',
                disc: x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00',
                uom: x.uom,
                amount: x.amount ? (Math.round(x.amount * 100) / 100).toFixed(2) : '0.00',
                actualDisc: dis[y] ? dis[y] : x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00',
                //actualRate: x.unitPrice ? (Math.round(x.salePrice * 100) / 100).toFixed(2) : '0.00',
              }
            })
            setDis(cloneDis)
            setPro(clonePro)
            setValue("sales", salesValue)
            setCalculate(result.data)
            setProductPriceData(result.data.lineItems)
            if (result.data && result.data.total) {
              let totalRoundOff = parseFloat(Math.round(result.data.total));
              if (totalRoundOff >= 50000 && isEInvoice) {
                setEWayBill(true);
              } else {
                setEWayBill(false)
              }
            }
            if (result.data && result.data.lineItems && result.data.lineItems.length > 0) {
              let findVal = result.data.lineItems.find((lineitem) => {
                let productName = lineitem && lineitem.productName
                let isTransport = (productName && productName.toUpperCase().includes("TRANSPORT")) ? true : false;
                return isTransport
              })
              setIsTransportDetails(findVal ? true : false)
            }
          }
        } else {
          setErrHSN(errHSN);
        }
      } else {
        setErrQtyProd(errqtyProd);
      }
    }
    catch (err) {
      setIsError(true)
    }
  }

  const handleClose = () => {
    reset();
    setCalculate(null);
    setDis([])
    setPro([])
    setSelectedPo({ poNum: "", products: [] })
  }

  const handleCheckout = () => {
    if (customerData) {
      if (isTransDetailsMandtory) {
        if (transportDetails.dispatchDocNo && transportDetails.dispatchThrough) {
          if (isEInvoice) {
            let requiredDetails = true;
            if (!(editCustDetails && editCustDetails.tradeName && editCustDetails.mobileNumber &&
              editCustDetails.address1 && editCustDetails.locality && editCustDetails.pincode && editCustDetails.state && editCustDetails.GSTIN)) {
              setBDNotFilled(true);
              requiredDetails = false;
            }
            if (!(editConsigneeDetails && editConsigneeDetails.tradeName && editConsigneeDetails.address1 &&
              editConsigneeDetails.locality && editConsigneeDetails.pincode && editConsigneeDetails.state && editConsigneeDetails.GSTIN)) {
              setCDNotFilled(true);
              requiredDetails = false;
            }
            if (!(editDispatchDetails && editDispatchDetails.tradeName && editDispatchDetails.address1 &&
              editDispatchDetails.locality && editDispatchDetails.pincode && editDispatchDetails.state)) {
              setDDNotFilled(true);
              requiredDetails = false;
            }
            if (isEWayBill) {
              if (editEWayBillDetails && editEWayBillDetails.transportMode) {
                if (editEWayBillDetails.transportMode === "1") {
                  if (!(editEWayBillDetails.distance && editEWayBillDetails.vehicleType &&
                    editEWayBillDetails.vehicleNumber)) {
                    setEDNotFilled(true);
                    requiredDetails = false;
                  }
                } else if (editEWayBillDetails.transportMode) {
                  if (!(editEWayBillDetails.distance && editEWayBillDetails.transporterName && editEWayBillDetails.transporterDocNo &&
                    editEWayBillDetails.transporterDocDate && editEWayBillDetails.transportMode)) {
                    setEDNotFilled(true);
                    requiredDetails = false;
                  }
                } else {
                  setEDNotFilled(true);
                  requiredDetails = false;
                }
              } else {
                setEDNotFilled(true);
                requiredDetails = false;
              }
            }
            if (requiredDetails) {
              setTransportError(false)
              setPayments({ ...payments, open: true })
            } else {
              setRequiredDetailsNot(true)
            }
          } else {
            setTransportError(false)
            setPayments({ ...payments, open: true })
          }

        } else {
          setTransportError(true)
        }
      } else {
        if (isEInvoice) {
          let requiredDetails = true;
          if (!(editCustDetails && editCustDetails.tradeName && editCustDetails.mobileNumber &&
            editCustDetails.address1 && editCustDetails.locality && editCustDetails.pincode && editCustDetails.state && editCustDetails.GSTIN)) {
            setBDNotFilled(true);
            requiredDetails = false;
          } else {
            setBDNotFilled(false);
          }
          if (!(editConsigneeDetails && editConsigneeDetails.tradeName && editConsigneeDetails.address1 &&
            editConsigneeDetails.locality && editConsigneeDetails.pincode && editConsigneeDetails.state && editConsigneeDetails.GSTIN)) {
            setCDNotFilled(true);
            requiredDetails = false;
          } else {
            setCDNotFilled(false);
          }
          if (!(editDispatchDetails && editDispatchDetails.tradeName && editDispatchDetails.address1 &&
            editDispatchDetails.locality && editDispatchDetails.pincode && editDispatchDetails.state)) {
            setDDNotFilled(true);
            requiredDetails = false;
          } else {
            setDDNotFilled(false);
          }
          if (isEWayBill) {
            if (editEWayBillDetails && editEWayBillDetails.transportMode) {
              if (editEWayBillDetails.transportMode === "1") {
                if (!(editEWayBillDetails.distance && editEWayBillDetails.vehicleType &&
                  editEWayBillDetails.vehicleNumber)) {
                  setEDNotFilled(true);
                  requiredDetails = false;
                } else {
                  setEDNotFilled(false);
                }
              } else if (editEWayBillDetails.transportMode) {
                if (!(editEWayBillDetails.distance && editEWayBillDetails.transporterName && editEWayBillDetails.transporterDocNo &&
                  editEWayBillDetails.transporterDocDate && editEWayBillDetails.transportMode)) {
                  setEDNotFilled(true);
                  requiredDetails = false;
                } else {
                  setEDNotFilled(false);
                }
              } else {
                setEDNotFilled(true);
                requiredDetails = false;
              }
            } else {
              setEDNotFilled(true);
              requiredDetails = false;
            }
          } else {
            setEDNotFilled(false);
          }
          if (requiredDetails) {
            setTransportError(false)
            setPayments({ ...payments, open: true })
          } else {
            setRequiredDetailsNot(true)
          }
        } else {
          setTransportError(false)
          setPayments({ ...payments, open: true })
        }
      }
    }
    else {
      setIsError(true)
    }
  }

  const handleDraft = async () => {
    try {
      if (customerData) {
        const draftProds = getValues({ nest: true })["sales"];
        const postreq = {
          customerId: customerData.id,
          orgCode: customerData.orgCode,
          locationId: store
        }
        const patchreq = { status: "DRAFT" };
        const lineItems = draftProds && draftProds.map(x => {
          const item = {
            productId: x.productId && Number(x.productId),
            qty: x.qty && Number(x.qty),
            HSNCode: x.HSNCode && Number(x.HSNCode),
            productPrice: x.rate ? (Math.round(x.rate * 100) / 100).toFixed(2) : '0.00',
          }
          if (x.disc > 0) {
            item.overridenDiscountPcnt = (Math.round(x.disc * 100) / 100).toFixed(2);
          } else if (x.actualDisc > 0) {
            item.overridenDiscountPcnt = (Math.round(x.actualDisc * 100) / 100).toFixed(2);
          }
          return item;
        });
        postreq.lineItems = lineItems;
        patchreq.lineItems = lineItems;
        if (id) {
          const result = await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/draftsale/${id}`,
            patchreq,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (result.status === 200) {
            setMessage(result && result.data && result.data.message);
            setIsSuccess(true);
          }
        } else {
          const result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/draftsale`,
            postreq,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (result.status === 200) {
            setMessage(result && result.data && result.data.message);
            setIsSuccess(true);
          }
          result && result.data && result.data.draftId && history.push({
            pathname: "/recordSales/" + result.data.draftId,
            state: customerData && customerData
          })
        }
      }

    } catch (err) {
      setIsError(true)
    }
  }

  const handleDeleteDraft = async () => {
    try {
      if (id) {
        const result = await axios.delete(
          `${process.env.REACT_APP_SERVICE_URL}/draftsale/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.status === 200) {
          setMessage(result && result.data && result.data.message);
          setIsSuccess(true);
        }
      }
    } catch (err) {
      setIsError(true)
    }
  }
  const rateValidation = (val) => {
    const valid = Number(val) > 0;
    return valid;
  }

  const handleReset = (e) => {
    history.push({
      pathname: "/recordSales",
      state: customerData && customerData,
    });
    window.location.reload();
    // setQuery({ page: 1, pageSize: pageSize })
  }

  let pageHeader = (invEdit) ? "Edit Sale" : "Record Sale";

  const saleVals = getValues({ nest: true })["sales"];

  const onEditCust = () => {
    let editCust = {
      tradeName: customerData.fullName,
      email: customerData.email,
      mobileNumber: customerData.mobileNumber,
      address1: customerData.address1,
      address2: customerData.address2,
      address3: customerData.address3,
      pincode: customerData.pincode,
      locality: customerData.locality,
      state: customerData.state,
      GSTIN: customerData.GSTIN,
      stateCode: customerData.state
    }
    setEditCustDetails(editCustDetails ? editCustDetails : editCust);
    setShowCustForm(true);
  }

  const onEditConsingee = () => {
    let editConsignee = {
      tradeName: customerData.fullName,
      address1: customerData.address1,
      address2: customerData.address2,
      address3: customerData.address3,
      pincode: customerData.pincode,
      locality: customerData.locality,
      state: customerData.state,
      GSTIN: customerData.GSTIN,
      stateCode: customerData.state
    }
    setEditConsingeeDetails(editConsigneeDetails ? editConsigneeDetails : editConsignee);
    setShowConsingeeForm(true);
  }

  const onEditDispatch = () => {
    let editDispatch = {
      tradeName: orgDetails && orgDetails.name,
      address1: orgDetails && orgDetails.address1,
      address2: orgDetails && orgDetails.address2,
      pincode: orgDetails && orgDetails.pincode,
      locality: orgDetails && orgDetails.address3,
      state: orgDetails && orgDetails.state,
      stateCode: orgDetails && orgDetails.state
    }
    setEditDispatchDetails(editDispatchDetails ? editDispatchDetails : editDispatch);
    setShowDispatchForm(true);
  }

  const onEditEwayBill = () => {
    let editEwayBill = {
      transporterId: "",
      transporterName: "",
      distance: null,
      transporterDocNo: "",
      transporterDocDate: "",
      vehicleNumber: "",
      vehicleType: "",
      supplyType: "",
      supplySubType: "",
      transportMode: null
    }
    setEditEWayBillDetails(editEWayBillDetails ? editEWayBillDetails : editEwayBill);
    setShowEWayBillForm(true);
  }

  return (
    customerData ? <Grid container xs={12} spacing={2}>
      <Grid item container xs={12} direction="column">
        <Grid item>
          <Box mt={2}>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              {isAdmin && <Link color="inherit" href="" onClick={() => history.push("/customers")}>
                Customers
              </Link>}
              <Typography component="span" color="textPrimary">
                {pageHeader}
              </Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
      </Grid>
      <Grid item container xs={4} md={4} style={{ alignContent: "flex-start" }}>
        <Box style={{ fontWeight: "bold" }}>Customer Name And Address:
          {(isEditAll && !isSaved) && <IconButton size="small" onClick={() => onEditCust()}>
            <EditIcon color="primary" />
          </IconButton>}
        </Box>
        <Box ml={2}>
          <Typography>{(editCustDetails && editCustDetails.tradeName) ? editCustDetails.tradeName : customerData && customerData.fullName}</Typography>
          <Typography>{(editCustDetails && editCustDetails.address1) ? editCustDetails.address1 : customerData && customerData.address1}</Typography>
          <Typography>{(editCustDetails && editCustDetails.address2) ? editCustDetails.address2 : customerData && customerData.address2}</Typography>
          <Typography>{(editCustDetails && editCustDetails.address3) ? editCustDetails.address3 : customerData && customerData.address3}</Typography>
        </Box>
      </Grid>
      <Grid item container xs={4} md={4}  >
        <Grid item xs={12}>
          <Box><Typography style={{ fontWeight: "bold" }}>Mobile Number:&nbsp;&nbsp;</Typography><Typography>{(editCustDetails && editCustDetails.mobileNumber) ? editCustDetails.mobileNumber : customerData && customerData.mobileNumber}</Typography></Box>
        </Grid>
        <Grid item xs={12}>
          <Box><Typography style={{ fontWeight: "bold" }}>Email:&nbsp;&nbsp;</Typography><Typography>{(editCustDetails && editCustDetails.email) ? editCustDetails.email : customerData && customerData.email}</Typography></Box>
        </Grid>
      </Grid>
      <Grid item container xs={4} md={4}  >
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Location <span style={{ color: "red" }}>*</span></Box>
            {(id) ? locName : <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="locality"
                value={store}
                disabled={id}
                onChange={(e) => {
                  setStore(e.target.value);
                }}
                variant="outlined"
                style={{ width: "100%" }}
              >
                {inventoryLocations.map(function (locality) {
                  return (
                    <MenuItem key={locality} value={locality.id}>
                      {locality.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Cost Center <span style={{ color: "red" }}>*</span></Box>
            {(id) ? costCenterName : <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="locality"
                value={costCenterName}
                style={{ width: "100%" }}
                disabled={id}
                onChange={(e) => {
                  setCostCenterName(e.target.value);
                }}
                variant="outlined"
              >
                {costCenterList.map(function (costCenter) {
                  return (
                    <MenuItem key={costCenter} value={costCenter.name}>
                      {costCenter.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ width: '100%' }}>
            <Box variant="caption" style={{ fontWeight: "bold" }}>Bank Details<span style={{ color: "red" }}>*</span></Box>
            {(id) ? bankName : <FormControl
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                name="bankId"
                value={bankId}
                disabled={id}
                onChange={(e) => {
                  setBank(e.target.value);
                }}
                variant="outlined"
                style={{ width: "100%" }}
              >
                {bankList && bankList.map(function (bank) {
                  return (
                    <MenuItem key={bank} value={bank.id}>
                      {bank.bankName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>}</Typography>
        </Grid>
      </Grid>

      {inventoryLocations.length > 0 ? <Grid container xs={12} spacing={2}>
        <Grid item container xs={6} md={6} justifyContent="flex-start" >
          <IconButton
            onClick={() => {
              if (!isSaved) {
                setShowPo(true);
              }
            }} disabled={custPO || id}
            style={{ backgroundColor: 'transparent' }} >
            <Typography style={{ fontWeight: "bold", color: "black", pointerEvents: "none" }}>Customer Purchase Order No:</Typography>
            &nbsp;
            <Typography>{custPO ? custPO : selectedPo.poNum}</Typography>
            &nbsp;
            {!custPO && <SearchIcon />}
          </IconButton></Grid>
        <Grid item container xs={6} md={6} justifyContent="flex-end" >
          <IconButton
            onClick={() => {
              setShowDiscount(true);
            }}
            style={{ display: discountInfo.length === 0 ? "none" : "" }}
          >
            <img src={discountIcon} alt="history" />
          </IconButton>
          &nbsp;
          <IconButton
            onClick={() => {
              setShowInvErr(true);
            }}
            style={{ display: eInvEWBErrorDetails.length === 0 ? "none" : "" }}
          >
            <img src={ErrInvoiceReportIcon} alt="history" />
          </IconButton>
        </Grid>
      </Grid> : <Grid container xs={12} spacing={2} justifyContent="center">
        <Box mt={10} variant="subtitle1" style={{ fontWeight: "bold" }} component="span">
          You don't have access to store.Kindly contact your Admin.
        </Box>
      </Grid>}
      {inventoryLocations.length > 0 ? <Grid item container xs={12} direction="column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell align="left" style={{ width: "20%" }}>Product Name</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "10%" }}>HSN Code</StyledHeaderCell>
                  <StyledHeaderCell align="left">Qty</StyledHeaderCell>
                  <StyledHeaderCell align="left">Rate</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "10%" }}>Disc %</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "5%" }}>Available Qty</StyledHeaderCell>
                  <StyledHeaderCell align="left" style={{ width: "5%" }}>UOM</StyledHeaderCell>
                  <StyledHeaderCell align="left">Amount</StyledHeaderCell>
                  <StyledHeaderCell align="left"></StyledHeaderCell>
                </TableRow>
              </TableHead>
              {fields.length === 0 ? (
                <TableBody>
                  {((selectedPo.poNum && selectedPo.products && selectedPo.products.length === 0) || (custPO)) && <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography variant="subtitle1">
                        Customer Purchase Order doesn't have eligible products for sale
                      </Typography>
                    </TableCell>
                  </TableRow>}
                  {!custPO && <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Button
                        size="small"
                        variant="contained"
                        align="center"
                        color="primary"
                        onClick={(e) => {
                          setSelectedPo({ poNum: "", products: [] })
                          append({
                            saleId: "",
                            productName: "",
                            HSNCode: "",
                            qty: "",
                            rate: "",
                            disc: "",
                            avail: "",
                            uom: "",
                            amount: "",
                            isQtyDecimal: false
                          });
                        }}
                      >
                        Add Product
                      </Button>
                    </TableCell>
                  </TableRow>}
                </TableBody>
              ) : null}
              <TableBody>
                {fields.map((sale, index) => {
                  return (
                    <TableRow key={sale.id}>
                      <TableCell align="left">
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productName
                          )}
                          inputRef={register({
                            required: "Product is required",
                          })}
                          onClick={(e) => setSelectProduct({ ...selectProduct, open: true, lineKey: index, create: true })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                                {!(selectedPo.poNum) && <SearchIcon onClick={(e) => setSelectProduct({ ...selectProduct, open: true })} />}
                              </InputAdornment>
                            ),
                            style: { fontSize: "14px" },
                          }}
                          size="small"
                          name={`sales[${index}].productName`}
                          style={{ fontSize: "14px !important" }}
                          multiline
                          rows={1}
                          rowsMax={3}
                          key={sale.id} disabled
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productName : sale.productName ? sale.productName : ''}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productId
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].productId`}
                          key={sale.id} disabled
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productId : sale.productId ? sale.productId : ''}
                        // defaultValue={props.form.data.sales && (props.form.data.sales)[index] ? (props.form.data.sales)[index].product && (props.form.data.sales)[index].product.id:''}
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productData
                          )}
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].productData`}
                          key={sale.id} disabled={selectedPo.poNum}
                          defaultValue={selectedPo.products[index] ? selectedPo.products[index].productData : ''}
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].productName &&
                            errors.sales[index].productName.message}
                        </FormHelperText>

                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          name={`sales[${index}].HSNCode`}
                          defaultValue={sale.HSNCode}
                          inputRef={register({
                            required: "HSN is required",
                          })}
                          disabled={(isSaved || !isEditAll) ? true : false}
                          variant="standard"
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].HSNCode
                          ) || (_.includes(errHSNProIndex, index)) ? true : false}
                        />
                        <FormHelperText error={Boolean(
                          errors.sales &&
                          errors.sales[index] &&
                          errors.sales[index].HSNCode
                        ) || (_.includes(errHSNProIndex, index)) ? true : false}>
                          {((errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].HSNCode &&
                            errors.sales[index].HSNCode.message) || (_.includes(errHSNProIndex, index)) ? true : false) ? "Enter Valid HSN Code" : ""}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].qty
                          ) || (_.includes(errQtyProIndex, index)) ? true : false}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Quantity is required",
                            pattern: {
                              value: /^[0-9]*.[0-9]{0,3}$/,
                              message: "Quantity is required",
                            },
                          })}
                          onChange={(e) => {
                            const qty = e.target.value;
                            const saleVal = getValues({ nest: true })["sales"];
                            if (saleVal[index] && qty >= Number(saleVal[index].avail)) {
                              const qtyKey = `sales[${index}].qty`;
                              setValue(qtyKey, Number(saleVal[index].avail))
                            }
                            if (selectedPo.poNum) {
                              const poItems = selectedPo && selectedPo.products;
                              const poQty = poItems && poItems[index] ? poItems[index].qty : "";
                              if (qty >= Number(poQty)) {
                                if (saleVal[index] && qty >= Number(saleVal[index].avail)) {
                                  const qtyKey = `sales[${index}].qty`;
                                  setValue(qtyKey, Number(saleVal[index].avail))
                                }
                                else {
                                  const qtyKey = `sales[${index}].qty`;
                                  setValue(qtyKey, Number(poQty))
                                }

                              }
                            }
                          }}
                          disabled={isPo ? false : (isSaved || !isEditAll) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          size="small"
                          name={`sales[${index}].qty`}
                          defaultValue={sale.qty}
                          variant="standard"
                        />
                        <FormHelperText error={Boolean(
                          errors.sales &&
                          errors.sales[index] &&
                          errors.sales[index].qty
                        ) || (_.includes(errQtyProIndex, index)) ? true : false}>
                          {((errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].qty &&
                            errors.sales[index].qty.message) || (_.includes(errQtyProIndex, index)) ? true : false) ? "Enter Valid Qty" : ""}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].rate
                          )}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Rate is required",
                            pattern: {
                              value: /^(?:\d*\.\d{1,2}|\d+)$/,
                              message: "Provide valid rate",
                            },
                            validate: (value) => rateValidation(value) || 'Price should be greater than 0'
                          })}
                          disabled={isPo ? false : (isSaved || !isEditAll) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          size="small"
                          name={`sales[${index}].rate`}
                          defaultValue={sale.rate}
                          variant="standard"
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].actualRate`}
                          key={sale.id} disabled
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].rate &&
                            errors.sales[index].rate.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          error={Boolean(
                            errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].disc
                          )}
                          inputProps={{ style: { textAlign: 'right' } }}
                          inputRef={register({
                            required: "Discount is required",
                            pattern: {
                              value: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/,
                              message: "Provide a valid discount rate",
                            },
                          })}
                          size="small"
                          name={`sales[${index}].disc`}
                          disabled={isPo ? false : (isSaved || !isEditAll) ? true : !(fields && fields[index] && fields[index].productId) && !(saleVals && saleVals[index] && saleVals[index].productId)}
                          defaultValue={sale.disc}
                          variant="standard"
                        />
                        <TextField
                          variant="standard"
                          fullWidth
                          inputRef={register({
                          })}
                          size="small"
                          style={{ display: "none" }}
                          name={`sales[${index}].actualDisc`}
                          key={sale.id} disabled
                        />
                        <FormHelperText error>
                          {errors.sales &&
                            errors.sales[index] &&
                            errors.sales[index].disc &&
                            errors.sales[index].disc.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          disabled
                          size="small"
                          name={`sales[${index}].avail`}
                          defaultValue={sale.avail}
                          inputRef={register({
                          })}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled
                          size="small"
                          name={`sales[${index}].uom`}
                          inputRef={register({
                          })}
                          defaultValue={sale.uom}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          name={`sales[${index}].amount`}
                          inputRef={register({
                          })}
                          defaultValue={sale.amount}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell style={{ display: 'none' }}>
                        <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right', display: 'none' } }}
                          inputRef={register({})}
                          size="small"
                          name={`sales[${index}].isQtyDecimal`}
                          defaultValue={
                            (sale &&
                              sale.isQtyDecimal) ? true : false
                          }
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell align="right" style={{ width: isPo ? '14%' : '16%' }}>
                        {(isPo || custPO) ? "" :
                          <IconButton
                            size="small"
                            disabled={!isEditAll || isSaved}
                            onClick={(e) => {
                              insert(index + 1, {
                                saleId: "",
                                productName: "",
                                HSNCode: "",
                                qty: "",
                                rate: "",
                                disc: "",
                                avail: "",
                                uom: "",
                                amount: "",
                                productId: "",
                                isQtyDecimal: false
                              });
                              setErrHSN([]);
                              setErrQtyProd([]);
                            }
                            }
                          >
                            <AddIcon color={(!isEditAll || isSaved) ? "" : "secondary"} />
                          </IconButton>}
                        {(isPo || custPO) ? "" : <span>&nbsp;</span>}
                        <IconButton
                          size="small"
                          disabled={!isEditAll || isSaved}
                          onClick={(e) => {
                            remove(index);
                            setCalculate(null);
                            setErrHSN([]);
                            setErrQtyProd([]);
                          }}
                        >
                          <DeleteIcon color={(!isEditAll || isSaved) ? "" : "secondary"} />
                        </IconButton>
                        &nbsp;
                        <IconButton size="small" disabled={!isEditAll || isSaved} onClick={() => onCalculateClick(index)}>
                          <img src={(!isEditAll || isSaved) ? calculateDisabledIcon : calculateIcon} alt="history" />
                        </IconButton>
                        &nbsp;
                        <IconButton size="small" disabled={(!calculate || !isEditAll || isSaved)} onClick={() => onInfoClick(index)}>
                          {(!calculate || !isEditAll || isSaved) ? <img src={infoIconDisabled} alt="history" /> : <img src={infoIcon} alt="history" />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={selectProduct.open}
            onClose={() => {
              setSelectProduct({ ...selectProduct, open: false });
            }}
          >
            <DialogTitle>
              <Grid container>
                <Grid item container xs={9} alignItems="center">
                  <Grid item>
                    Select Product
                  </Grid>
                </Grid>
                <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setSelectProduct({ ...selectProduct, open: false });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent >
              <SearchProduct
                form={selectProduct}
                setValue={setValue}
                getValues={getValues}
                sales={getValues({ nest: true })["sales"]}
                setIsWarning={setIsWarning}
                setSafetyStockWarning={setSafetyStockWarning}
                setCalculate={setCalculate}
                saleIdentifier={true}
                addonData={{ customerType: customerData.custTypeId, locationId: store }}
                cancel={() => {
                  setSelectProduct({ ...selectProduct, open: false });
                }}
                success={() => {
                  setSelectProduct({ ...selectProduct, open: false, success: true });
                }}
              />
            </DialogContent>
          </Dialog>
          {fields.length !== 0 && !isSaved && <Grid item container xs={12} justifyContent="flex-end">
            <Box mt={2}>
              <Button
                size="small"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSaved}
                style={{ marginRight: "10px" }}
              >
                Calculate
              </Button>
              <Button
                disabled={!isEditAll || isSaved}
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                Clear
              </Button>
            </Box>
          </Grid>}
        </form>


        {calculate && <Box mt={18}>
          <List dense={true} style={{ backgroundColor: "#F5F5F5", fontSize: "14px" }}>
            <ListItem alignItems="flex-start" divider={true} >
              <Grid item container xs={12}>
                <Grid item xs={11}>
                  Subtotal
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" justifyContent="flex-end"> {calculate.subtotal && (Math.round(calculate.subtotal * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start" divider={true}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  GST
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start" divider={true}>

              <Grid item container xs={12} >
                <Grid item xs={11} >
                  <Box ml={2} >IGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box display="flex" justifyContent="flex-end">{calculate.IGST && (Math.round(calculate.IGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
                <Grid item xs={11} >
                  <Box mt={0.3} ml={2}>CGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box mt={1} display="flex" justifyContent="flex-end">{calculate.CGST && (Math.round(calculate.CGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
                <Grid item xs={11} >
                  <Box ml={2} >SGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box display="flex" justifyContent="flex-end">{calculate.SGST && (Math.round(calculate.SGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start">
              <Grid item container xs={12} style={{ fontWeight: "bold" }}>
                <Grid item xs={11}>
                  Total
                </Grid>
                <Grid item xs={1} style={{ borderLeft: "4px solid #FFFFFF" }}>
                  <Box display="flex" justifyContent="flex-end"> {calculate.total && (Math.round(calculate.total * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <Box mt={2} style={{ fontSize: "12px" }}>{`Amount Chargeable(In words): ${calculate.amountInWords}`}</Box>
          {!isSaved && <Grid container spacing={2} alignItems="baseline" style={{ marginTop: "15px" }}>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Dispatch Doc No. / Transport Document No </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl >
                  <TextField
                    helperText={(!transportDetails.dispatchDocNo && isTransportError && isTransDetailsMandtory) ? "Required" : ""}
                    onChange={e => setTransportDetails({ ...transportDetails, dispatchDocNo: e.target.value })}
                    error={!transportDetails.dispatchDocNo && isTransportError && isTransDetailsMandtory}
                    value={transportDetails.dispatchDocNo}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Dispatch Through / Transport Mode</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl >
                  <TextField
                    helperText={(!transportDetails.dispatchThrough && isTransportError && isTransDetailsMandtory) ? "Required" : ""}
                    onChange={e => setTransportDetails({ ...transportDetails, dispatchThrough: e.target.value })}
                    error={!transportDetails.dispatchThrough && isTransportError && isTransDetailsMandtory}
                    value={transportDetails.dispatchThrough}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={6} xs={12}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Notes</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  name="remarkNote"
                  inputProps={{ maxLength: 150 }}
                  placeholder="Notes..."
                  rows={2}
                  rowsMax={5}
                  size="small"
                  onChange={e => setInvoiceRemark(e.target.value)}
                  value={remarkNote}
                />
                <FormHelperText >
                  Textfield allow only 500 character.
                </FormHelperText>
              </Grid>
            </Grid>
            {/* <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Destination</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <FormControl >
                <TextField
                  helperText={(!transportDetails.distanation && isTransportError && isTransDetailsMandtory)?"Required":""}
                  onChange = {e => setTransportDetails({ ...transportDetails, distanation: e.target.value})}
                  error={!transportDetails.distanation && isTransportError && isTransDetailsMandtory}
                  value={transportDetails.distanation}
                  variant="outlined"
                  size="small"
                />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Vehicle No.</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <FormControl >
                <TextField
                    helperText={(!transportDetails.vehicleNo && isTransportError && isTransDetailsMandtory)?"Required":""}
                    onChange = {e => setTransportDetails({ ...transportDetails, vehicleNo: e.target.value})}
                    error={!transportDetails.vehicleNo && isTransportError && isTransDetailsMandtory}
                    value={transportDetails.vehicleNo}
                    variant="outlined"
                    size="small"
                />
              </FormControl>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Vehicle LR-RR</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <FormControl >
                <TextField
                      helperText={(!transportDetails.vehicleLLR && isTransportError && isTransDetailsMandtory)?"Required":""}
                      onChange = {e => setTransportDetails({ ...transportDetails, vehicleLLR: e.target.value})}
                      error={!transportDetails.vehicleLLR && isTransportError && isTransDetailsMandtory}
                      value={transportDetails.vehicleLLR}
                      variant="outlined"
                      size="small"
                />
              </FormControl>
              </Grid>
            </Grid> */}
          </Grid>}
          {!isSaved && <Box mt={3}>
            {discountInfo.length !== 0 && <Typography color="primary" mt={2}>Based on the Sales Total, this Invoice qualifies for an additional
              Invoice level discount. Refer to Discounts section to know more.</Typography>}
            {!(invEdit) && <Button style={{ marginRight: '10px' }} color="primary" size="small" variant="contained" onClick={() => handleDraft()}>
              Save AS Draft </Button>}
            <Button disabled={!isEditAll} onClick={onEditConsingee} color="primary" size="small" variant="contained" style={{ marginRight: '10px' }}>
              Edit Consignee Details </Button>
            <Button disabled={!isEditAll} onClick={onEditDispatch} color="primary" size="small" variant="contained" style={{ marginRight: '10px' }}>
              Edit Dispatch Details</Button>
            {isEWayBill && <Button disabled={!isEditAll && !isEditEWB} onClick={onEditEwayBill} color="primary" size="small" variant="contained" style={{ marginRight: '10px' }}>
              Edit E-way bill Details </Button>}
            <Button onClick={handleCheckout} color="primary" size="small" variant="contained" style={{ marginRight: '10px' }}>
              Checkout </Button>
            {(id && invEdit) ?
              <Button onClick={() => { setDeleteInv({ ...deleteInv, open: true }) }} disabled={!isEditAll} size="small" variant="contained" color="secondary" style={{ display: "none" }}> Cancel Sale</Button> :
              id ? <Button size="small" variant="contained" color="secondary"
                onClick={() => handleDeleteDraft()}>Delete Draft</Button> : ''}
          </Box>}
          {isSaved && <Box mt={3}>
            {isSaleMsgWarning && <Typography style={{ color: "red" }} mt={2}>View e-Invoice and EWB Error Report</Typography>}
          </Box>}
          {isSaved &&
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                onClick={handleReset}
                color="primary"
                size="small"
                variant="contained"
                style={{ marginRight: 10 }}
              >
                New Sale
              </Button>{!deleteSuccess && <>
                <ReactToPrint
                  onBeforeGetContent={() => handleInvoicePrint(invoiceNo)}
                  trigger={() => <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    style={{ marginRight: 10 }}
                  >
                    PRINT INVOICE
                  </Button>}
                  content={() => componentRefInv.current} />
                <Button
                  onClick={openEmailInovice}
                  color="primary"
                  size="small"
                  variant="contained"
                >
                  Email
                </Button></>}
            </Box>
          }
        </Box>}

      </Grid> : ""}
      {/* Dummy Funtion */}
      <span onClick={onPaymentmethodChange} style={{ display: "none" }}>Dummy</span>

      {/* Confirm box */}
      {isConfirmBox ?
        <ConfirmationDialog onClose={confirmDialogBox} />
        : null}
      {/* payments

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={payments.open}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <h2 style={{ color: "#0c4b60" }}>
                Payments
              </h2>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPayments({ ...payments, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Grid item xs={12}>
            <Payment
              cancel={() => {
                setPayments({ ...payments, open: false });
                setPaymentMethod(null);
                setPaymentMethodCode("");
                setIsPaymentMethod(false);
              }}
              setInvoiceNo={setInvoiceNo}
              setIsError={setIsError}
              setIsSaleWarning={setIsSaleWarning}
              setIsSaleMsgWarning={setIsSaleMsgWarning}
              setIsSuccess={setIsSuccess}
              setIsSaved={setIsSaved}
              setMessage={setMessage}
              calculate={calculate}
              invoiceId={tempInvNo}
              erpInvoiceId={erpInvNo}
              customerId={CustomerId}
              paymentMethodId={paymentMethod}
              customerData={editCustDetails}
            />
          </Grid>
        </DialogContent>
      </Dialog> */}

      {/* payments */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={payments.open}
        onClose={() => {
          setPayments({ ...payments, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Payments
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPayments({ ...payments, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Payment
            cancel={() => {
              setPayments({ ...payments, open: false });
            }}
            setIsError={setIsError}
            setIsSaleWarning={setIsSaleWarning}
            setIsSaleMsgWarning={setIsSaleMsgWarning}
            setIsSuccess={setIsSuccess}
            setIsSaved={setIsSaved}
            setInvoiceNo={setInvoiceNo}
            calculate={calculate}
            setMessage={setMessage}
            poNum={isPo ? selectedPo.poNum : custPO}
            customerData={customerData}
            store={store}
            costCenter={costCenterName}
            orgBankId={bankId}
            isInvEdit={invEdit}
            saleId={id}
            transportDetails={transportDetails}
            paymentDetails={paymentDetails}
            isEWayBill={isEWayBill}
            isBtoB={isCustWholeSale}
            isEInvoice={isEInvoice}
            sellerDetails={sellerDetails}
            buyerDetails={editCustDetails}
            consigneeDetails={editConsigneeDetails}
            dispatchDetails={editDispatchDetails}
            eWayBillDetails={editEWayBillDetails}
            invoiceRemarkNote={remarkNote}
          />
        </DialogContent>
      </Dialog>

      {/* discounts */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={showDiscount}
        onClose={() => {
          setShowDiscount(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Discounts
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowDiscount(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Discount setIsError={setIsError} discountInfo={discountInfo} />
        </DialogContent>
      </Dialog>
      {/* eInv EWB error */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={showInvErr}
        onClose={() => {
          setShowInvErr(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Error lists
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowInvErr(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <ErrorLists setIsError={setIsError} erroInfo={eInvEWBErrorDetails} />
        </DialogContent>
      </Dialog>
      {/* po */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={showPo}
        onClose={() => {
          setShowPo(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Customer Purchase Orders
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowPo(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Po remove={remove} setIsError={setIsError} setSelectedPo={setSelectedPo} store={store} setShowPo={setShowPo} />
        </DialogContent>
      </Dialog>


      {/* calculator */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={priceData.open}
        onClose={() => {
          setPriceData({ ...priceData, open: false });
        }}
      >
        <DialogTitle style={{ paddingBottom: "0px" }}>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Price Calculator
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPriceData({ ...priceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Calculator
            cancel={() => {
              setPriceData({ ...priceData, open: false });
            }}
            priceData={priceData}
            intraState={editCustDetails && orgDetails && editCustDetails.stateCode !== orgDetails.state}
          />
        </DialogContent>
      </Dialog>

      {/*cancel sale/invoice */}
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={deleteInv.open}
        onClose={() => {
          setDeleteInv({ ...deleteInv, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Reason for Cancelling Sale
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setDeleteInv({ ...deleteInv, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <DeleteSale
            cancel={() => {
              setDeleteInv({ ...deleteInv, open: false });
            }}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
            setDeleteSuccess={setDeleteSuccess}
            setIsSaved={setIsSaved}
            setMessage={setMessage}
            isInvEdit={invEdit}
            saleId={id}
          />
        </DialogContent>
      </Dialog>

      {/* price */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={productPriceData.open}
        onClose={() => {
          setProductPriceData({ ...productPriceData, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Product Price Information(Per Unit)
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProductPriceData({ ...productPriceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <PriceDetermine
            cancel={() => {
              setProductPriceData({ ...productPriceData, open: false });
            }}
            priceData={productPriceData}
          />
        </DialogContent>
      </Dialog>
      {/* Invoice email */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Email Invoice
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: '180px' }}>
          <Grid container>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span" >Mail to *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                inputRef={register({
                  required: "Email Id is required",
                })}
                value={emailId}
                onChange={e => setEmailSendData({ ...emailSendData, emailId: e.target.value })}
              />
              {isEmailHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter valid email id
              </FormHelperText>}
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: '10px' }}>
            <Grid xs={3}>
              <Typography className='recordSaleInvoiceLabel' style={{ fontWeight: "bold" }} component="span">Subject *</Typography>
            </Grid>
            <Grid item md={9}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                inputRef={register({
                  required: "Email Subject is required",
                })}
                value={emailSubject}
                onChange={e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value })}
              />
              {isEmailSubjectHelpertext && <FormHelperText style={{ color: 'red' }}>
                Enter email subject
              </FormHelperText>}
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              onClick={onSendEmailInvoice}
              color="secondary"
              size="small"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Customer edit from*/}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={showCustForm}
        onClose={() => {
          setShowCustForm(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Customer Details
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowCustForm(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <CustomerEditForm customerData={editCustDetails} onEditDataSubmit={onEditDataCustSubmit} onEditCustCancel={onEditCustCancel} required={isEInvoice} />
        </DialogContent>
      </Dialog>
      {/* consingee from*/}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={showConsingeeForm}
        onClose={() => {
          setShowConsingeeForm(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Consignee Details
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowConsingeeForm(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <ConsigneeForm consigneeData={editConsigneeDetails} onEditDataSubmit={onEditConsigneeDataSubmit} onEditCancel={onEditConsigneeCancel} required={isEInvoice} />
        </DialogContent>
      </Dialog>
      {/* Dispatch from*/}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={showDispatchForm}
        onClose={() => {
          setShowDispatchForm(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Dispatch Details
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowDispatchForm(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <DispatchAddressForm dispatchData={editDispatchDetails} onEditDataSubmit={onEditDispatchDataSubmit} onEditCancel={onEditDispatchCancel} required={isEInvoice} />
        </DialogContent>
      </Dialog>

      {/* e-Way Bill from*/}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={showEWayBillForm}
        onClose={() => {
          setShowEWayBillForm(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                E-way Bill Details
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowEWayBillForm(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <EWayBillDetailsForm eWayBillData={editEWayBillDetails} onEditDataSubmit={onEditEWayBillDataSubmit} onEditCancel={onEditEWayBillCancel} required={isEInvoice} />
        </DialogContent>
      </Dialog>

      <Box display="none">
        <CompToPrintInv ref={componentRefInv} customerInv={invoice} loggedInUser={loggedInUser} orgBankDetails={orgBankDetails} orgTermsAndConditions={orgTermsAndConditions} />
        <ReactToPrint
          onBeforeGetContent={() => handleInvoicePrint(invoiceNo)}
          trigger={() => <Button
            color="primary"
            size="small"
            variant="contained"
            ref={buttonRef}
          >
            PRINT INVOICE
          </Button>}
          content={() => componentRefInv.current} />
      </Box>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {message ? message : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setIsSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message ? message : "Thanks for shopping!"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isWarning}
        autoHideDuration={3000}
        onClose={() => setIsWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          {"Product is currently not available"}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={isSaleWarning}
        autoHideDuration={3000}
        onClose={() => setIsSaleWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsSaleWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          {message ? message : "Error form ASP port unble to generate eInvoice/EWB"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isRequiredDetailsNot}
        autoHideDuration={3000}
        onClose={() => setRequiredDetailsNot(false)}
      >
        <MuiAlert
          onClose={() => setRequiredDetailsNot(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          Enter required {isBDNotFilled ? "Customer details " : ""}{isCDNotFilled ? "Consignee details " : ""}{isDDNotFilled ? "Dispatch details " : ""}{isEDNotFilled ? "E-way bill details " : ""}
        </MuiAlert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={safetyStockWarning}
        onClose={() => {
          setSafetyStockWarning(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={10} alignItems="center">
              <Grid item >
                Product availability is low. Check Safety Stock.
              </Grid>
            </Grid>
            <Grid item container xs={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSafetyStockWarning(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} >
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => setSafetyStockWarning(false)}
                  color="primary"
                  size="medium"
                  variant="contained"
                  style={{ marginRight: 10 }}
                >
                  OK
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
      </Dialog>
    </Grid> : <Grid item container xs={12} direction="column">
      <Grid item>
        <Box mt={2}>
          <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
            <Link color="inherit" href="" onClick={() => history.push("/")}>
              Home
            </Link>
            {isAdmin && <Link color="inherit" href="" onClick={() => history.push("/customers")}>
              Customers
            </Link>}
            <Typography component="span" color="textPrimary">
              Record Sale
            </Typography>
          </Breadcrumbs>
        </Box>
      </Grid>
      <Grid><Box mt={2}>Kindly select a Customer to Record Sale</Box></Grid>
    </Grid>

  );
};

export default RecordSaleSlide;
