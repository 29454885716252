import React from "react";
import { useHistory } from "react-router-dom";

import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon
} from "@material-ui/core";

import customerSale from "../../images/customerSales.svg";
import salesPurchaseInvoice from "../../images/salesPurchaseInvoice.svg";
import paymentEntry from "../../images/paymentEntry.svg";
import journalEntry from "../../images/journalEntry.svg";
import creditNote from "../../images/creditNote.svg";
import debitNote from "../../images/debitNote.svg";

import dailyInventory from "../../images/dailyInventory.svg";
import replenishment from "../../images/replenishment.svg";
import productPrice from "../../images/productPrice.svg";
import CustomerOrderReport from "../../images/Customer_order_report.svg";
import InventoryMovementReport from "../../images/Inventory_movement.svg";
import CancelledSalesReport from "../../images/CancelledSales.svg";
import ErrInvoiceReport from "../../images/ErrorReport.svg";
import UnsyncedSalesReport from "../../images/UnsyncedSales.svg";
import PreviousSalePrice from "../../images/previousSalePrice.svg";
import ProductStatusReport from "../../images/ProductStatus_Report.svg";
import trialBalance from "../../images/trialBalance.svg";
import balanceSheet from "../../images/balanceSheet.svg";
import generalLedger from "../../images/generalLedger.svg";
import profitandLoss from "../../images/profitandLoss.svg";
import ledgerBalance from "../../images/ledgerBalance.svg";

const Reports = (props) => {
  const history = useHistory();

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary">
                Reports
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid item container xs={12} md={3} direction="row">
          <List subheader={<ListSubheader style={{ color: "#0C4B60", fontWeight: "bold" }}>List of Reports</ListSubheader>} >
            <ListItem>
              <ListItemIcon>
                <img src={CustomerOrderReport} alt="order" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/customerOrders")}
              >
                Customer Purchase Orders
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={customerSale} alt="sale" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/customerSales")}
              >
                Customer Sales
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={salesPurchaseInvoice} alt="purchase" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/supplierpurchase")}
              >
                Supplier Purchase
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={paymentEntry} alt="purchase" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/paymententrylists")}
              >
                Payment Entries
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={creditNote} alt="creditnote" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/creditnotelist")}
              >
                Credit Notes
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={debitNote} alt="debitnote" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/debitnotelist")}
              >
                Debit Notes
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={journalEntry} alt="purchase" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/journallists")}
              >
                Journal Entries
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={UnsyncedSalesReport} alt="customerTempSales" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2" 
                onClick={() => history.push("/customerTempSales")}
              >
                Customer Temporary Sales
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={UnsyncedSalesReport} alt="unsyncedSales" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/unSyncCustSales")}
              >
                Unsynced Sales
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={CancelledSalesReport} alt="cancelledInv" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/cancelledInvReport")}
              >
                Cancelled Sales Report
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={ErrInvoiceReport} alt="errInv" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/eInvEWBErrorReport")}
              >
                e-Inv & EWB Error Report
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={dailyInventory} alt="dailysync" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/inventorySync")}
              >
                Daily Inventory Sync
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={replenishment} alt="dailysync" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/replenishmentInsight")}
              >
                Replenishment Insight
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={productPrice} alt="productPrice" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/priceReport")}
              >
                Missing Price Report
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={InventoryMovementReport} alt="productPrice" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/invMovementReport")}
              >
                Stock Entry Report
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={PreviousSalePrice} alt="custSales" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/customerprevioussales")}
              >
                Product Price Report
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={ProductStatusReport} alt="prodStatus" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/productstatusreport")}
              >
                Product Status Report
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={trialBalance} alt="dailysync" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/trailBalanceReport")}
              >
                Trial Balance 
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={balanceSheet} alt="dailysync" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/balanceSheetReport")}
              >
                Balance Sheet
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={generalLedger} alt="dailysync" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/generalLedgerReport")}
              >
                General Ledger
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={profitandLoss} alt="dailysync" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/profitAndLossReport")}
              >
                Profit And Loss
              </Link>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <img src={ledgerBalance} alt="dailysync" />
              </ListItemIcon>
              <Link
                component="button"
                variant="body2"
                onClick={() => history.push("/customerLedgerReport")}
              >
                Customer Ledger Balance
              </Link>
            </ListItem>
          </List>
        </Grid>
      </div>
    </div>
  );
};

export default Reports;
